.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
}

.content-container {
  min-height: calc(100vh - 34px);
}

.MDBCarousel {
  padding-left: 0px
}
.button4 {border-radius: 30px !important;
  color: #757575 !important;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffffff;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.mmu:hover {
  background-color: rgb(12, 12, 11) !important;
  color: #ffffff !important;
  
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
  background-color: rgb(211, 18, 69) ;
  color: #2feb09 !important;
  font-size: 18px;
}

.ant-tabs {
  color: rgba(211, 18, 69, 0.85);
  font-size: 18px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(211, 18, 69)  !important;
  text-shadow: 0 0 0.25px currentcolor;

  line-height: 2.5715;
  list-style: circle;
 
}

/* ::selection {
  color: #fff;
  background: #555f68;
} */

.service {
  background-color: #e0e0e0;
  /* border-radius: 30px !important; */
}

.service3 {
  background-color: #616161;
  /* border-radius: 30px !important; */
}

/* .ant-tabs-tab:hover {
  background-color: #cac8c8;
  border-radius: 20px !important;
  color: #ff4099;
} */
.ant-tabs-tab:hover{
  color: rgb(211, 18, 69) !important;
}

.heaol{
  background-color: #d01440 !important;
}


.service2 {
  /* background-color: #eeeeee ; */
  background-image: url("./Pages/img/bg1.jpg");
}



.foot1 {

  /* background-image: url("./Pages/img/foot1.jpg"); */
  background-color: #4e4d4d !important;
  min-height: 25vh;
  height: fit-content;
  /* background: -webkit-linear-gradient(
    45deg,
    rgba(66, 133, 244, 0.7),
    rgba(13, 71, 161, 0.7) 100%
  ); */

 
}



