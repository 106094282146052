@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap);
/* //@import url('https://fonts.googleapis.com/css?family=Sarabun&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200&display=swap'); */
body {
  margin: 0;
  font-family: 'Prompt', sans-serif!important;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
}

#apppage .gradient {
  /* background: -moz-linear-gradient(
    45deg,
    rgba(244, 66, 96, 0.7),
    rgba(161, 13, 25, 0.7) 100%
  ); */
  /* background: -webkit-linear-gradient(
    45deg,
    rgba(244, 66, 96, 0.7),
    rgba(161, 13, 25, 0.7) 100%
  ); */
  background: linear-gradient(
    45deg,
    rgba(243, 242, 242, 0.925),
    rgba(226, 217, 218, 0.795) 10%
  );
}

#apppage .view {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Others/architecture.webp"); */
  /* background-image: url("https://i.ytimg.com/vi/VFTeAXOOcEg/maxresdefault.jpg"); */
  background-image: url("https://chiangmaibankforklift.com/bankbackend/S__53501976.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 35vh;
  margin-top: 0 ;
  
}

#apppage h6 {
  line-height: 1.7;
}

/* :where(.css-dev-only-do-not-override-byeoj0).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff16e0 !important;
    text-shadow: 0 0 0.25px currentcolor;
      border: 1px solid #ff16e0;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    background-color: rgb(240, 13, 32) ;
    color: #2feb09 !important;
    font-size: 18px;
  }
  .ant-tabs-tab:hover{
    color: blueviolet !important;
  } */



body, html {
  height: 100%;
}
.bg {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp"); */
  background-image: url("https://images.pexels.com/photos/3377405/pexels-photo-3377405.jpeg?cs=srgb&dl=pexels-el%C4%ABna-ar%C4%81ja-3377405.jpg&fm=jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.App {
  font-family: sans-serif;
  text-align: center;
}

.toplea {
  position: absolute; 
  top: 500px;  
}

.ww {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp"); */
  height: 60% !important;
  width: 80% !important;
  position: absolute !important;
  padding-left: 2px !important;
  /* padding-Right: 2px !important; */
  margin: left 5px ;
  /* margin: 0 auto; */
  /* left: 3;
  right: 5; */
  /* top: 0;
  bottom: 0;
  margin: auto; */

}
.element.style{
  width: 80% !important;
}

.h1 {
  font-size: 40px;
  background: -webkit-linear-gradient(#b80303, #1f1e1e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke: 0.3px rgb(245, 242, 242); */
}

.aa {
  font-size: 30px;
  background: -webkit-linear-gradient(#b80303, #1f1e1e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke: 0.3px rgb(245, 242, 242); */
}


.gradientss {
  background: linear-gradient(
    45deg,
    rgba(66, 133, 244, 0.7),
    rgba(13, 71, 161, 0.7) 100%
  );
}

#apppage .gradient {
  background: -moz-linear-gradient(
    45deg,
    rgba(66, 133, 244, 0.7),
    rgba(13, 71, 161, 0.7) 100%
  
  );
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
}

.content-container {
  min-height: calc(100vh - 34px);
}

.MDBCarousel {
  padding-left: 0px
}
.button4 {border-radius: 30px !important;
  color: #757575 !important;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffffff;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.mmu:hover {
  background-color: rgb(12, 12, 11) !important;
  color: #ffffff !important;
  
}

.hover-underline-animation:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
  background-color: rgb(211, 18, 69) ;
  color: #2feb09 !important;
  font-size: 18px;
}

.ant-tabs {
  color: rgba(211, 18, 69, 0.85);
  font-size: 18px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(211, 18, 69)  !important;
  text-shadow: 0 0 0.25px currentcolor;

  line-height: 2.5715;
  list-style: circle;
 
}

/* ::selection {
  color: #fff;
  background: #555f68;
} */

.service {
  background-color: #e0e0e0;
  /* border-radius: 30px !important; */
}

.service3 {
  background-color: #616161;
  /* border-radius: 30px !important; */
}

/* .ant-tabs-tab:hover {
  background-color: #cac8c8;
  border-radius: 20px !important;
  color: #ff4099;
} */
.ant-tabs-tab:hover{
  color: rgb(211, 18, 69) !important;
}

.heaol{
  background-color: #d01440 !important;
}


.service2 {
  /* background-color: #eeeeee ; */
  background-image: url(/static/media/bg1.e816b827.jpg);
}



.foot1 {

  /* background-image: url("./Pages/img/foot1.jpg"); */
  background-color: #4e4d4d !important;
  min-height: 25vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background: -webkit-linear-gradient(
    45deg,
    rgba(66, 133, 244, 0.7),
    rgba(13, 71, 161, 0.7) 100%
  ); */

 
}




